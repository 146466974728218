import { I18nChildContext, t } from "@bookingcom/lingojs-react";
import { registerDynamicTags } from "@bookingcom/lingojs-core";

// register possible branded logo alt
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_autohuren-nl"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_bravofly"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_car-rentals"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_easyterra"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_happy-car"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_jet2"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_lastminute"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_pepecar"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_qatarairways"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_rastreator"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_rumbo"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_ryanair"));
registerDynamicTags(t("gt_mig_tr_header_brandlogoalttext_volagratis"));

// register possible branded copyright
registerDynamicTags(t("gt_mig_tr_common_text_copyright"));
registerDynamicTags(t("gt_mig_tr_common_text_copyright_ryanair"));

export const getBrandedTranslation = ({
	i18n,
	tag,
	brand,
}: {
	i18n: I18nChildContext;
	tag: string;
	brand: string;
}): string => {
	const sanitisedBrand = brand?.trim().toLowerCase();
	const brandedTag = sanitisedBrand === "rentalcars.com" ? tag : `${tag}_${sanitisedBrand}`;
	const translation = i18n.trans(t(brandedTag));
	// if translation is not found for the brand return default brand translation
	return translation === brandedTag ? i18n.trans(t(tag)) : translation;
};

const BRANDED_TAGS = ["gt_mig_tr_header_brandlogoalttext_", "gt_mig_tr_common_text_copyright_"];
export const isBrandNotFoundError = (errorMessage: string): boolean =>
	BRANDED_TAGS.some((tag) => errorMessage?.includes(tag));
