import { SVG } from "@bookingcom/bui-react/components/Icon/Icon.types";
import { getIcon } from "./icons";
import { Tab } from "./navigationUtils";

export type HeaderTab = {
	id: string;
	text: string;
	icon?: SVG;
	href?: string;
	native?: boolean;
};

export const mapProducts = (products: Tab[], rentalcarsDomain: string): HeaderTab[] => {
	if (!products) {
		return [];
	}
	return products
		?.filter((product) => product.id !== "packages")
		.map((product) => {
			return {
				text: product.label,
				id: product.id,
				href: product.id === "cars" ? rentalcarsDomain : product.href,
				icon: getIcon(product.id),
				native: true,
			};
		});
};
