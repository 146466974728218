import React, { FC, useMemo, useState } from "react";
import { useTranslations } from "@bookingcom/lingojs-react";
import { LinesHorizontalIcon } from "@bookingcom/bui-assets-react/streamline";
import { Divider, SheetContainer, Stack, Button, Image, Box, Actionable, Text, Hidden } from "@bookingcom/bui-react";
import { IExperiment } from "@btransport/react-experimentation";
import { DictionaryObject } from "@bookingcom/lingojs-parser";
import { CurrencySelect } from "../CurrencySelect";
import { HelpSelect } from "../HelpSelect";
import { LanguageSelect } from "../LanguageSelect";
import { HeaderProvider } from "../../context/HeaderContext";
import { LanguageData } from "../../type";
import { CobrandHeader } from "../CobrandHeader";
import { ManageBooking } from "../ManageBooking";
import { NavBar } from "../NavBar";
import { HeaderTab, mapProducts } from "../../util/header/product-tabs";
import { windowExists } from "../../util/windowExists";
import { Tab } from "../../util/header/navigationUtils";
import { getBrandedTranslation } from "../../util/brandedTranslations/brandedTranslations";

interface HeaderComponentProps {
	languageData: LanguageData;
	pageName?: string;
	disableCurrency?: boolean;
	disableLanguage?: boolean;
	brand: string;
	affiliateCode?: string;
	showCobrandHeader?: boolean;
	cobrandImageUrl?: string;
	correlationId?: string;
	brandLogoUrl: string;
	brandLogoWidth: string | number;
	brandLogoHeight: string | number;
	brandLogoHyperLink: string;
	tabs?: Tab[];
	renderRcByBookingNav?: boolean;
	renderRcByBookingLogo?: boolean;
	rentalcarsDomain: string;
}

export interface HeaderProps extends HeaderComponentProps {
	lingoTranslations: DictionaryObject;
	abuLocale: string;
	currency: string;
	paymentCurrency: string;
	updateUrlOnLanguageChange?: boolean;
	experiments?: Record<string, IExperiment>;
	defaultViewportSize: "small" | "large";
	languagesToHide?: string[];
	currenciesToHide?: string[];
}

const HeaderControls: FC<Pick<HeaderComponentProps, "disableCurrency" | "disableLanguage" | "languageData">> = ({
	disableCurrency,
	disableLanguage,
	languageData,
}) => {
	const { translate: t } = useTranslations();

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const openModal = (event?): void => {
		event?.preventDefault();
		setIsModalOpen(true);
	};

	const closeModal = (): void => {
		setIsModalOpen(false);
	};

	return (
		<>
			<Hidden below="large">
				<Stack direction="row">
					{!disableCurrency && <CurrencySelect />}
					{!disableLanguage && <LanguageSelect languageData={languageData} />}
					<HelpSelect />
					<Box padding={2} orientation="horizontal">
						<ManageBooking textColor="brand_primary" />
					</Box>
				</Stack>
			</Hidden>
			<Hidden above="medium">
				<Stack direction="row">
					<Stack.Item className="header-menu-modal-button__root">
						<Button
							size="large"
							onClick={openModal}
							variant="light"
							icon={<LinesHorizontalIcon />}
							attributes={{
								"data-testid": "menu-button",
								"aria-label": t("gt_mig_tr_signindropdown_menu"),
							}}
						/>
					</Stack.Item>
					<SheetContainer
						position="fullScreen"
						id="menu-overlay"
						attributes={{
							"data-testid": "menu-overlay",
							"aria-label": t("gt_mig_tr_signindropdown_menu"),
						}}
						closeAriaLabel="Close"
						active={isModalOpen}
						onCloseTrigger={closeModal}
						title={t("gt_mig_tr_signindropdown_menu")}
						keepMounted
					>
						<Box padding={1} orientation="vertical">
							<Divider />
						</Box>
						<Box padding={3}>
							<Stack gap={3}>
								{!disableCurrency && (
									<CurrencySelect
										justifyContent="start"
										onCurrencySelectOpen={closeModal}
										onCurrencySelectClose={openModal}
									/>
								)}
								{!disableLanguage && (
									<LanguageSelect
										justifyContent="start"
										onLangaugeSelectOpen={closeModal}
										onLangaugeSelectClose={openModal}
										languageData={languageData}
									/>
								)}
								<HelpSelect justifyContent="start" />
								<ManageBooking justifyContent="start" />
							</Stack>
						</Box>
					</SheetContainer>
				</Stack>
			</Hidden>
		</>
	);
};

const HeaderComponent: FC<HeaderComponentProps> = ({
	languageData,
	pageName,
	disableCurrency,
	disableLanguage,
	brand,
	affiliateCode,
	showCobrandHeader,
	cobrandImageUrl,
	correlationId,
	brandLogoUrl,
	brandLogoWidth,
	brandLogoHeight,
	brandLogoHyperLink,
	tabs,
	renderRcByBookingNav,
	renderRcByBookingLogo,
	rentalcarsDomain,
}) => {
	const { translate: t, i18n } = useTranslations();

	const supportedProducts = useMemo<HeaderTab[]>(() => mapProducts(tabs, rentalcarsDomain), [tabs, rentalcarsDomain]);
	return (
		<header role="banner">
			<Box padding={0} backgroundColor="brand_primary" attributes={{ "data-testid": "header-component" }}>
				<div className="header-container-root">
					<a aria-label={t("gt_mig_tr_header_skipcontent")} className="skip-main" href="#main" data-testid="skip-link">
						{t("gt_mig_tr_header_skipcontent")}
					</a>
					<Stack justifyContent="start" alignItems="center" className="header-container" direction="row">
						<Stack.Item shrink>
							{renderRcByBookingLogo ? (
								<Actionable
									href={brandLogoHyperLink}
									onClick={() => {
										window.publishMicroConversionEvent(`${pageName} - Header`, "Clicked", "Header Logo");
									}}
									attributes={{ "data-testid": "brand-logo-hyperlink" }}
								>
									<Stack direction="row" alignItems="stretch" className="header-logo">
										<Image
											attributes={{ "data-testid": "brand-logo-image" }}
											contentMode="fill"
											src={brandLogoUrl}
											alt={getBrandedTranslation({ tag: "gt_mig_tr_header_brandlogoalttext", brand, i18n })}
											className="header-logo_rentalcars"
											priority
										/>
										<Text tagName="p" variant="body_1" className="header-logo_subtext">
											by
										</Text>
										<Image
											asset={{
												setName: "images-brand",
												assetName: "BookingComLogoDarkBackgroundsMono",
											}}
											contentMode="fill"
											alt="Booking.com"
											className="header-logo_booking"
											priority
										/>
									</Stack>
								</Actionable>
							) : (
								<Actionable
									href={brandLogoHyperLink}
									onClick={() => {
										window.publishMicroConversionEvent(`${pageName} - Header`, "Clicked", "Header Logo");
									}}
									attributes={{ "data-testid": "brand-logo-hyperlink" }}
								>
									<Image
										attributes={{ "data-testid": "brand-logo-image" }}
										contentMode="fit"
										src={brandLogoUrl}
										alt={getBrandedTranslation({ tag: "gt_mig_tr_header_brandlogoalttext", brand, i18n })}
										width={brandLogoWidth}
										height={brandLogoHeight}
										priority
									/>
								</Actionable>
							)}
						</Stack.Item>
						<Stack.Item split>
							<HeaderControls
								disableCurrency={disableCurrency}
								disableLanguage={disableLanguage}
								languageData={languageData}
							/>
						</Stack.Item>
					</Stack>
					{renderRcByBookingNav && supportedProducts.length > 0 && (
						<NavBar
							tabs={supportedProducts}
							pageName={pageName}
							fireInteractionEvent={windowExists && window.publishMicroConversionEvent}
						/>
					)}
				</div>
			</Box>
			{showCobrandHeader && (
				<CobrandHeader
					brand={brand}
					affiliateCode={affiliateCode}
					correlationId={correlationId}
					imageUrl={cobrandImageUrl}
				/>
			)}
		</header>
	);
};

export const Header: FC<HeaderProps> = ({
	lingoTranslations,
	abuLocale,
	languageData,
	currency,
	paymentCurrency,
	pageName = "unknown-mfe",
	disableCurrency = false,
	disableLanguage = false,
	updateUrlOnLanguageChange = false,
	brand,
	affiliateCode = "unknown-aff-code",
	showCobrandHeader = false,
	cobrandImageUrl = "",
	correlationId = "",
	experiments = {},
	brandLogoUrl,
	brandLogoWidth,
	brandLogoHeight,
	brandLogoHyperLink,
	tabs,
	renderRcByBookingNav = false,
	renderRcByBookingLogo = false,
	rentalcarsDomain,
	defaultViewportSize,
	languagesToHide = [],
	currenciesToHide = [],
}) => (
	<HeaderProvider
		pageName={pageName}
		abuLocale={abuLocale}
		lingoTranslations={lingoTranslations}
		selectedLanguage={languageData.code}
		selectedCurrency={currency}
		paymentCurrency={paymentCurrency}
		updateUrlOnLanguageChange={updateUrlOnLanguageChange}
		experiments={experiments}
		defaultViewportSize={defaultViewportSize}
		currenciesToHide={currenciesToHide}
		languagesToHide={languagesToHide}
	>
		<HeaderComponent
			languageData={languageData}
			pageName={pageName}
			disableCurrency={disableCurrency}
			disableLanguage={disableLanguage}
			brand={brand}
			affiliateCode={affiliateCode}
			showCobrandHeader={showCobrandHeader}
			cobrandImageUrl={cobrandImageUrl}
			correlationId={correlationId}
			brandLogoUrl={brandLogoUrl}
			brandLogoWidth={brandLogoWidth}
			brandLogoHeight={brandLogoHeight}
			brandLogoHyperLink={brandLogoHyperLink}
			tabs={tabs}
			renderRcByBookingNav={renderRcByBookingNav}
			renderRcByBookingLogo={renderRcByBookingLogo}
			rentalcarsDomain={rentalcarsDomain}
		/>
	</HeaderProvider>
);
