const languageToImage = {
	ar: "ae",
	ca: "catalonia",
	cs: "cz",
	da: "dk",
	en: "gb",
	he: "il",
	ja: "jp",
	ko: "kr",
	sl: "si",
	sv: "se",
	sw: "tz",
	uk: "ua",
	vi: "vn",
	zh: "z4",
	zs: "cn",
	hi: "in",
};

export const EuCountries = [
	"AT",
	"BE",
	"BG",
	"CY",
	"CZ",
	"DE",
	"DK",
	"EE",
	"FI",
	"GR",
	"HR",
	"HU",
	"IE",
	"IT",
	"LT",
	"LU",
	"LV",
	"MT",
	"NL",
	"PO",
	"PT",
	"RO",
	"SE",
	"SI",
	"SK",
	"FR",
	"ES",
];

export const getImageCode = (languageCode: string): string => {
	const language = languageCode.toLowerCase();
	return languageToImage[language] || language;
};
