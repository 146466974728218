import React, { FC } from "react";
import { Image } from "@bookingcom/bui-react";
import { getImageCode } from "../../util/cor/corUtils";

interface LanguageIconProps {
	language: string;
	width?: number;
	height?: number;
}

export const LanguageIcon: FC<LanguageIconProps> = ({ language, width = 24, height = 24 }) => (
	<Image
		src={`https://cf.bstatic.com/static/img/flags/new/48-squared/${getImageCode(language)}.png`}
		alt={`${language} language icon`}
		className="language-icon"
		attributes={{ "data-testid": "language-icon" }}
		width={width}
		height={height}
		priority
	/>
);
