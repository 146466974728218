import React, { FC, ReactElement } from "react";
import { I18nProvider, Trans, useTranslations } from "@bookingcom/lingojs-react";
import { Box, Stack, Text, Link, Image, useViewport, BUIProvider } from "@bookingcom/bui-react";
import { getI18NStore } from "@btransport/lingo-client-utils";
import { DictionaryObject } from "@bookingcom/lingojs-parser";
import { getLogoUrls, linksGenerator } from "../../util/footer";
import { ProtectionInformationLink } from "../ProtectionInformationLink";
import { getBrandedTranslation, isBrandNotFoundError } from "../../util/brandedTranslations/brandedTranslations";

interface FooterComponentProps {
	pageName?: string;
	cor: string;
	language: string;
	brand: string;
	showInsuranceDisclaimer?: boolean;
	showCompanyGroupLogos: boolean;
	showAffiliateLink: boolean;
	showCareersLink: boolean;
	showSupplyPartnerEnquiryAndMarketplaceLink: boolean;
	showGenericDisclaimerMessage: boolean;
	showCopyrightText: boolean;
	abuLocale: string;
}

interface FooterProps extends FooterComponentProps {
	abuLocale: string;
	lingoTranslations: DictionaryObject;
}

const renderTaxNumber = (cor: string): ReactElement =>
	cor && cor.toUpperCase() === "ES" ? (
		<Text attributes={{ "data-testid": "tax-number" }}>
			<Trans tag="gt_mig_tr_footer_taxnumber" />
		</Text>
	) : null;

const renderFooterText = (showGenericDisclaimerMessage: boolean): ReactElement =>
	showGenericDisclaimerMessage ? (
		<Text attributes={{ "data-testid": "generic-disclaimer-text" }}>
			<Trans tag="gt_mig_tr_footer_car_rental_webpages_disclaimer" />
		</Text>
	) : (
		<>
			<Text attributes={{ "data-testid": "about-text" }}>
				<Trans tag="gt_mig_tr_footer_about" />
			</Text>
			<Text attributes={{ "data-testid": "legal-text" }}>
				<Trans tag="gt_mig_tr_footer_rentalcarsstatement" />
			</Text>
		</>
	);

const FooterComponent: React.FC<FooterComponentProps> = ({
	pageName = "unkown-mfe",
	cor,
	language,
	brand,
	showInsuranceDisclaimer = false,
	showCompanyGroupLogos,
	showAffiliateLink,
	showCareersLink,
	showSupplyPartnerEnquiryAndMarketplaceLink,
	showGenericDisclaimerMessage,
	showCopyrightText,
	abuLocale,
}) => {
	const { i18n } = useTranslations();
	const { isMedium, isLarge } = useViewport();
	const footerLinks = linksGenerator({
		language,
		cor,
		showAffiliateLink,
		showCareersLink,
		showSupplyPartnerEnquiryAndMarketplaceLink,
		abuLocale,
	});
	const logoUrls = getLogoUrls();
	const showAffiliateLogos = showCompanyGroupLogos && (isMedium || isLarge);

	return (
		<footer id="footer" data-testid="footer-component" role="contentinfo">
			<Box padding={3} backgroundColor="brand_primary">
				<Stack gap={2} direction="row" attributes={{ "data-testid": "footer-links" }} justifyContent="center">
					{footerLinks.map((link) => (
						<Stack.Item key={link.key}>
							<Link
								className="footer-link"
								preventDefault={false}
								href={link.href}
								onClick={link.onClick}
								attributes={{ "data-testid": link.testId, target: "_blank" }}
							>
								<Trans tag={link.key} />
							</Link>
						</Stack.Item>
					))}
				</Stack>
			</Box>
			<Box padding={2} backgroundColor="neutral_alt" className="footer-content-root">
				<div className="footer-content">
					<Stack gap={3}>
						<Stack.Item>
							<Stack gap={3} direction={{ s: "column", m: "column", l: "row" }}>
								<Stack.Item grow>
									<Stack gap={2}>
										{renderFooterText(showGenericDisclaimerMessage)}
										{renderTaxNumber(cor)}
										{showInsuranceDisclaimer && <ProtectionInformationLink pageName={pageName} />}
									</Stack>
								</Stack.Item>
							</Stack>
						</Stack.Item>
						{showAffiliateLogos && (
							<Stack.Item>
								<Stack
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									className="brand-logos"
									attributes={{ "data-testid": "affiliate-logos" }}
								>
									{logoUrls.map(({ assetName, altText, width, height }) => (
										<Stack.Item>
											<Image
												contentMode="fit"
												asset={{
													setName: "images-brand",
													// eslint-disable-next-line @typescript-eslint/no-explicit-any
													assetName: assetName as any,
												}}
												alt={altText}
												key={altText}
												width={width}
												height={height}
											/>
										</Stack.Item>
									))}
								</Stack>
							</Stack.Item>
						)}
						{showCopyrightText && (
							<Stack.Item>
								<Text align="center" attributes={{ "data-testid": "copyright-text" }}>
									{getBrandedTranslation({ tag: "gt_mig_tr_common_text_copyright", brand, i18n })}
								</Text>
							</Stack.Item>
						)}
					</Stack>
				</div>
			</Box>
		</footer>
	);
};

export const Footer: FC<FooterProps> = ({
	abuLocale,
	lingoTranslations,
	pageName,
	cor,
	language,
	brand,
	showInsuranceDisclaimer,
	showCompanyGroupLogos,
	showAffiliateLink,
	showCareersLink,
	showSupplyPartnerEnquiryAndMarketplaceLink,
	showGenericDisclaimerMessage,
	showCopyrightText,
}) => {
	const i18n = getI18NStore(
		abuLocale,
		lingoTranslations,
		(errorMessage) => !isBrandNotFoundError(errorMessage) && console.error(errorMessage),
	);
	return (
		<I18nProvider store={i18n}>
			<BUIProvider>
				<FooterComponent
					language={language}
					pageName={pageName}
					cor={cor}
					brand={brand}
					showInsuranceDisclaimer={showInsuranceDisclaimer}
					showCompanyGroupLogos={showCompanyGroupLogos}
					showAffiliateLink={showAffiliateLink}
					showCareersLink={showCareersLink}
					showSupplyPartnerEnquiryAndMarketplaceLink={showSupplyPartnerEnquiryAndMarketplaceLink}
					showGenericDisclaimerMessage={showGenericDisclaimerMessage}
					showCopyrightText={showCopyrightText}
					abuLocale={abuLocale}
				/>
			</BUIProvider>
		</I18nProvider>
	);
};
