import React, { FC } from "react";
import { Icon, ListItem, Text, Stack, Grid } from "@bookingcom/bui-react";
import { CheckmarkIcon } from "@bookingcom/bui-assets-react/streamline";
import { languages } from "../../consts/languages";
import { useHeaderContext } from "../../context/HeaderContext";
import { LanguageIcon } from "../LanguageIcon";
import { changeLanguage } from "../../queries/change-preference";
import { LanguageData } from "../../type";

interface LanguageListProps {
	languageData: LanguageData;
}

interface LanguagePillProps {
	languageLabel: string;
	languageCode: string;
	active: boolean;
}

const LanguagePill: FC<LanguagePillProps> = ({ languageLabel, languageCode, active }) => {
	const { pageName, selectedLanguage, updateUrlOnLanguageChange } = useHeaderContext();

	return (
		<ListItem
			active={active}
			verticalAlignment="start"
			icon={<LanguageIcon language={languageCode} />}
			onClick={async () => {
				await window
					.publishMicroConversionEvent(`${pageName} - Header`, "Language clicked", languageCode, selectedLanguage)
					.finally(() => {
						return changeLanguage(languageCode, updateUrlOnLanguageChange);
					});
			}}
			attributes={{ "data-testid": `language-pill-${languageCode}${active ? "-selected" : ""}` }}
		>
			<Stack direction="row" justifyContent="space-between">
				<Text align="left" variant="body_2">
					{languageLabel}
				</Text>
				{active && <Icon svg={<CheckmarkIcon />} />}
			</Stack>
		</ListItem>
	);
};

export const LanguageList: FC<LanguageListProps> = ({ languageData }) => {
	const { languagesToHide } = useHeaderContext();
	return (
		<Grid gap={4}>
			{languages
				.filter((language) => !languagesToHide.includes(language.code.toLowerCase()))
				.map((language) => {
					return (
						<Grid.Column
							size={{
								s: 12,
								l: 3,
							}}
						>
							<LanguagePill
								languageLabel={language.label}
								languageCode={language.code.toLowerCase()}
								active={language.code.toLowerCase() === languageData.code.toLowerCase()}
							/>
						</Grid.Column>
					);
				})}
		</Grid>
	);
};
