import React, { FC, useState } from "react";
import { Trans } from "@bookingcom/lingojs-react";
import { Box, Stack, Text, useViewport } from "@bookingcom/bui-react";

import "./CobrandHeader.css";

export interface CobrandHeaderProps {
	brand: string;
	affiliateCode: string;
	imageUrl: string;
	correlationId: string;
}

const CobrandHeaderText: FC<{ children: React.ReactNode }> = ({ children }) => {
	const { isLarge } = useViewport();

	return (
		<Text tagName="p" variant={isLarge ? "body_2" : "small_2"}>
			{children}
		</Text>
	);
};

export const CobrandHeader: FC<CobrandHeaderProps> = ({ brand, affiliateCode, imageUrl, correlationId }) => {
	const { isLarge } = useViewport();
	const [hasError, setHasError] = useState(false);

	function onErrorHandler(event): void {
		event.currentTarget.removeEventListener("error", onErrorHandler);
		window.publishMicroConversionEvent(
			"CO_BRAND_HEADER",
			"PARTNER_LOGO_FAILED_LOAD",
			"CO_BRAND_LOGO",
			`{${brand}, ${affiliateCode}, ${imageUrl}, ${correlationId}, ${window.location.href}}`,
		);
		setHasError(true);
	}

	return (
		<Box
			padding={1}
			borderColor="neutral_alt"
			attributes={{ "data-testid": "cobrand-header" }}
			className="cobrand-header__container"
			backgroundColor="elevation_one"
		>
			<Stack direction={isLarge ? "column" : "row"} justifyContent="center" alignItems="center" gap={0}>
				<CobrandHeaderText>
					<Trans tag="gt_mig_tr_cobrand_inpartnershipwith_text" /> {hasError && brand}
				</CobrandHeaderText>
				{!hasError && (
					<img
						className="cobrand-header__image"
						src={imageUrl}
						alt={brand}
						loading="eager"
						width="auto"
						height={isLarge ? 52 : 32}
						onError={onErrorHandler}
					/>
				)}
			</Stack>
		</Box>
	);
};
