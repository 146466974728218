import React from "react";
import { Text, Stack, Icon, Button, SheetContainer, Divider, Tooltip, Box, Hidden } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import { Experiment, Variant } from "@btransport/react-experimentation";
import { ChangeCurrencyIcon } from "@bookingcom/bui-assets-react/streamline";
import classnames from "classnames";
import { CurrencyList } from "../CurrencyList";
import { useHeaderContext } from "../../context/HeaderContext";
import { getCurrency } from "../../util/header/currencyUtils";
import { Experiments } from "../../consts/experiments";

interface CurrencySelectProps {
	justifyContent?: undefined | "start" | "right" | "center";
	onCurrencySelectOpen?: () => void;
	onCurrencySelectClose?: () => void;
}

export const CurrencySelect = ({
	justifyContent,
	onCurrencySelectOpen,
	onCurrencySelectClose,
}: CurrencySelectProps): JSX.Element => {
	const { translate: t } = useTranslations();
	const { selectedCurrency } = useHeaderContext();
	const [isOpen, setIsOpen] = React.useState<boolean>(false);
	const { code: currencyCode, label: currencyLabel } = getCurrency(selectedCurrency);

	const openModal = (event): void => {
		event.preventDefault();
		setIsOpen(true);
		onCurrencySelectOpen?.();
	};

	const closeModal = (): void => {
		setIsOpen(false);
		onCurrencySelectClose?.();
	};

	const currencySelectContent = (attributes): JSX.Element => (
		<div>
			<Button
				wide
				variant="light"
				className={classnames("currency-select-button", {
					[`currency-select-button--justify-content-${justifyContent}`]: justifyContent,
				})}
				onClick={openModal}
				attributes={{
					...attributes,
					"data-testid": "currency-button",
					"aria-label": t("gt_mig_tr_header_languagecurrencypanel_selectcurrency"),
				}}
			>
				<Stack direction="row">
					<Hidden above="medium">
						<Stack direction="row">
							<Icon svg={<ChangeCurrencyIcon />} size="large" />
							<Text>{t(currencyLabel)}</Text>
						</Stack>
					</Hidden>
					<Hidden below="large">
						<Text>{currencyCode}</Text>
					</Hidden>
				</Stack>
			</Button>
			<SheetContainer
				position={{ s: "fullScreen", l: "center" }}
				onCloseTrigger={closeModal}
				closeAriaLabel="Close"
				active={isOpen}
				title={t("gt_mig_tr_header_languagecurrencypanel_selectcurrency")}
				attributes={{
					"aria-label": t("gt_mig_tr_header_languagecurrencypanel_selectcurrency"),
					"data-testid": "currency-list-popup",
				}}
				size={960}
			>
				<Box padding={1} orientation="vertical">
					<Divider />
				</Box>
				<Box padding={1} orientation="vertical" overflow="hidden">
					<CurrencyList />
				</Box>
			</SheetContainer>
		</div>
	);

	return (
		<Experiment name={Experiments.WRP_SMBOOK_HELP_HEADER}>
			<Variant value="A">{currencySelectContent({})}</Variant>
			<Variant value="B">
				<Tooltip text={t("gt_mig_tr_header_languagecurrencypanel_selectcurrency")} hideArrow position="bottom">
					{(attributes) => currencySelectContent(attributes)}
				</Tooltip>
			</Variant>
		</Experiment>
	);
};
