import React, { FC } from "react";
import { useTranslations, Trans } from "@bookingcom/lingojs-react";
import { NoteEditIcon } from "@bookingcom/bui-assets-react/streamline";
import { Button, Hidden, Icon, Stack, Text, TextProps } from "@bookingcom/bui-react";
import classnames from "classnames";
import { useHeaderContext } from "../../context/HeaderContext";

interface ManageBookingProps {
	textColor?: TextProps["color"];
	justifyContent?: undefined | "start" | "end" | "center";
	additionalClassNames?: string;
}

export const ManageBooking: FC<ManageBookingProps> = ({ additionalClassNames, textColor, justifyContent }) => {
	const { translate: t } = useTranslations();

	const { selectedLanguage, pageName } = useHeaderContext();

	const onButtonClickHandler = async (): Promise<void> => {
		await window.publishMicroConversionEvent(`${pageName} - Header`, "Clicked", "Manage My Booking").finally(() => {
			window.location.href = `/${selectedLanguage.toLowerCase()}/mybooking/`;
		});
	};

	return (
		<div className={classnames("manage-booking-button_root", additionalClassNames)}>
			<Button
				wide
				variant="white"
				onClick={onButtonClickHandler}
				className={classnames("manage-booking-button", {
					[`manage-booking-button--justify-content-${justifyContent}`]: justifyContent,
				})}
				attributes={{
					"data-testid": "manage-booking-button",
					"aria-label": t("gt_mig_tr_managebooking_managebooking"),
				}}
			>
				<Stack direction="row">
					<Hidden above="medium">
						<Icon svg={<NoteEditIcon />} size="large" />
					</Hidden>
					<Text color={textColor}>
						<Trans tag="gt_mig_tr_managebooking_managebooking" />
					</Text>
				</Stack>
			</Button>
		</div>
	);
};
