import React, { FC } from "react";
import { Link, Text } from "@bookingcom/bui-react";
import { Trans } from "@bookingcom/lingojs-react";

interface ProtectionInformationLinkProps {
	pageName: string;
}

export const ProtectionInformationLink: FC<ProtectionInformationLinkProps> = ({ pageName }) => (
	<Text attributes={{ "data-testid": "protection-information-text" }}>
		<Trans
			tag="gt_mig_tr_confetti_protection_information"
			variables={{
				link: "<0></0>",
			}}
			components={[
				<Link
					attributes={{ "data-testid": "protection-information-link", target: "_blank" }}
					preventDefault={false}
					onClick={() => {
						window.publishMicroConversionEvent(pageName, "CLICKED", "insurance-information");
					}}
					href="/protection-information"
				>
					<Trans tag="gt_mig_tr_protection_information_title" />
				</Link>,
			]}
		/>
	</Text>
);
