import React, { FC } from "react";
import { Button, Tooltip, Stack, Text, Icon, Hidden } from "@bookingcom/bui-react";
import { HelpIcon } from "@bookingcom/bui-assets-react/streamline";
import { useTranslations } from "@bookingcom/lingojs-react";
import { Experiment, Variant } from "@btransport/react-experimentation";
import classnames from "classnames";
import { useHeaderContext } from "../../context/HeaderContext";
import { Experiments } from "../../consts/experiments";

interface HelpSelectProps {
	justifyContent?: undefined | "start" | "end" | "center";
}

export const HelpSelect: FC<HelpSelectProps> = ({ justifyContent }) => {
	const { translate: t } = useTranslations();

	const { selectedLanguage, pageName } = useHeaderContext();

	return (
		<Experiment name={Experiments.WRP_SMBOOK_HELP_HEADER}>
			<Variant value="A" />
			<Variant value="B">
				<Tooltip text={t("gt_mig_tr_helpcentre_page_title")} hideArrow position="bottom">
					{(attributes) => (
						<Button
							wide
							variant="light"
							data-testid="help-button"
							className={classnames("help-select-button", {
								[`help-select-button--justify-content-${justifyContent}`]: justifyContent,
							})}
							onClick={() => {
								window.publishMicroConversionEvent(
									`${pageName} - Header`,
									"Clicked",
									"header_help_icon",
									`${window.location.pathname}`,
								);
								window.open(`/${selectedLanguage.toLowerCase()}/help/`, "_blank");
							}}
							attributes={{
								...attributes,
								"data-testid": "help-button",
								"aria-label": t("gt_mig_tr_helpcentre_page_title"),
							}}
						>
							<Stack direction="row" alignItems="center">
								<Icon svg={<HelpIcon />} size="large" />
								<Hidden above="medium">
									<Text>{t("gt_mig_tr_helpcentre_page_title")}</Text>
								</Hidden>
							</Stack>
						</Button>
					)}
				</Tooltip>
			</Variant>
		</Experiment>
	);
};
