import React, { FC } from "react";
import { Text, Stack, Button, SheetContainer, Divider, Tooltip, Box, Hidden } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import { Experiment, Variant } from "@btransport/react-experimentation";
import classnames from "classnames";
import { LanguageIcon } from "../LanguageIcon";
import { LanguageList } from "../LanguageList";
import { LanguageData } from "../../type";
import { Experiments } from "../../consts";

interface LanguageSelectProps {
	languageData: LanguageData;
	containerClassNames?: string;
	justifyContent?: undefined | "start" | "end" | "center";
	onLangaugeSelectOpen?: () => void;
	onLangaugeSelectClose?: () => void;
}

export const LanguageSelect: FC<LanguageSelectProps> = ({
	languageData,
	justifyContent,
	containerClassNames,
	onLangaugeSelectOpen,
	onLangaugeSelectClose,
}) => {
	const { translate: t } = useTranslations();

	const [isOpen, setIsOpen] = React.useState<boolean>(false);

	const openModal = (event): void => {
		event.preventDefault();
		setIsOpen(true);
		onLangaugeSelectOpen?.();
	};

	const closeModal = (): void => {
		setIsOpen(false);
		onLangaugeSelectClose?.();
	};

	const languageSelectContent = (attributes): JSX.Element => (
		<div className={containerClassNames}>
			<Button
				wide
				variant="light"
				className={classnames("language-select-button", {
					[`language-select-button--justify-content-${justifyContent}`]: justifyContent,
				})}
				onClick={openModal}
				attributes={{
					...attributes,
					"data-testid": "language-button",
					"aria-label": t("gt_mig_tr_header_selectlanguage"),
				}}
			>
				<Stack direction="row" alignItems="center">
					<LanguageIcon language={languageData.code.toLowerCase()} />
					<Hidden above="medium">
						<Text>{languageData.label}</Text>
					</Hidden>
				</Stack>
			</Button>
			<SheetContainer
				position={{ s: "fullScreen", l: "center" }}
				active={isOpen}
				attributes={{ "data-testid": "language-select-overlay" }}
				onCloseTrigger={closeModal}
				closeAriaLabel="Close"
				title={t("gt_mig_tr_header_selectlanguage")}
				aria-label={t("gt_mig_tr_header_selectlanguage")}
				closeAttributes={{ "data-testid": "language-close-button" }}
				size={960}
			>
				<Box padding={1} orientation="vertical">
					<Divider />
				</Box>
				<Box padding={1} orientation="vertical" overflow="hidden">
					<LanguageList languageData={languageData} />
				</Box>
			</SheetContainer>
		</div>
	);

	return (
		<Experiment name={Experiments.WRP_SMBOOK_HELP_HEADER}>
			<Variant value="A">{languageSelectContent({})}</Variant>
			<Variant value="B">
				<Tooltip text={t("gt_mig_tr_header_selectlanguage")} hideArrow position="bottom">
					{(attributes) => languageSelectContent(attributes)}
				</Tooltip>
			</Variant>
		</Experiment>
	);
};
