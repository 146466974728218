import { ImageProps } from "@bookingcom/bui-react";

interface LogoUrls {
	assetName: ImageProps["asset"]["assetName"];
	altText: string;
	width: number;
	height: number;
}

export const getLogoUrls = (): Array<LogoUrls> => {
	const logoUrls: Array<LogoUrls> = [
		{
			assetName: "BookingComLogoLightBackgroundsDynamic",
			altText: "Booking.com",
			width: 91,
			height: 26,
		},
		{
			assetName: "PricelineLogo",
			altText: "Priceline",
			width: 91,
			height: 26,
		},
		{
			assetName: "KayakLogo",
			altText: "Kayak",
			width: 79,
			height: 26,
		},
		{
			assetName: "AgodaLogo",
			altText: "Agoda",
			width: 70,
			height: 26,
		},
		{
			assetName: "RentalCarsLogo",
			altText: "Rentalcars",
			width: 109,
			height: 26,
		},
		{
			assetName: "OpenTableLogo",
			altText: "Opentable",
			width: 95,
			height: 26,
		},
	];

	return logoUrls;
};
