import React, { FC } from "react";
import { Stack, Icon, Text, ListItem, useViewport, Grid, Banner } from "@bookingcom/bui-react";
import { useTranslations } from "@bookingcom/lingojs-react";
import { CheckmarkIcon } from "@bookingcom/bui-assets-react/streamline";
import { currencies, Currency } from "../../consts/currencies";
import { useHeaderContext } from "../../context/HeaderContext";
import { changeCurrency } from "../../queries/change-preference";
import { getCurrency } from "../../util/header/currencyUtils";

interface CurrencyPillProps {
	currencyLabel: string;
	currencyCode: string;
	isActive: boolean;
	currencySymbol?: string;
	isIconActive?: boolean;
}

const CurrencyPill: FC<CurrencyPillProps> = ({
	currencyLabel,
	currencyCode,
	isActive,
	currencySymbol,
	isIconActive,
}) => {
	const { pageName, selectedCurrency } = useHeaderContext();
	return (
		<ListItem
			active={isActive}
			attributes={{ "data-testid": `currency-pill-${currencyCode}${isActive ? "-selected" : ""}` }}
			onClick={async () => {
				await window
					.publishMicroConversionEvent(`${pageName} - Header`, "Currency clicked", currencyCode, selectedCurrency)
					.finally(() => {
						return changeCurrency(currencyCode);
					});
			}}
			endSlot={isActive && isIconActive && <Icon svg={<CheckmarkIcon />} />}
		>
			<Stack gap={{ s: 4, l: 0 }} direction={{ s: "row", l: "column-reverse" }}>
				<Text align="left" color="neutral_alt">
					{currencySymbol || currencyCode}
				</Text>
				<Text align="left">{currencyLabel}</Text>
			</Stack>
		</ListItem>
	);
};

export const CurrencyList: FC = () => {
	const { translate: t } = useTranslations();
	const { isSmall } = useViewport();
	const { selectedCurrency, paymentCurrency, currenciesToHide } = useHeaderContext();

	const selectedCurrencyCode = getCurrency(selectedCurrency).code;
	const paymentCurrencyCode = getCurrency(paymentCurrency).code;

	const filteredCurrencies = Object.values(currencies).filter(
		(currencyObj) => !currenciesToHide.includes(currencyObj.code.toLowerCase()),
	);

	const currencyToPill: FC<Currency> = (currency) => {
		return (
			<Grid.Column
				size={{
					s: 12,
					l: 3,
				}}
			>
				<CurrencyPill
					currencyLabel={t(currency.label)}
					currencySymbol={currency?.code_label}
					currencyCode={currency.code}
					isActive={currency.code === selectedCurrencyCode}
					isIconActive={isSmall}
				/>
			</Grid.Column>
		);
	};

	return (
		<Stack gap={3}>
			<Banner
				title={
					<Text variant="strong_2" attributes={{ "data-testid": "alert-title" }}>
						{t("gt_mig_tr_currency_header", { variables: { var_0: selectedCurrencyCode } })}
					</Text>
				}
				text={
					<>
						<Text attributes={{ "data-testid": "currency-alert-body-one" }}>{t("gt_mig_tr_currency_body_1")}</Text>
						<Text attributes={{ "data-testid": "currency-alert-body-two" }}>
							{t("gt_mig_tr_currency_body_2", { variables: { var_0: paymentCurrencyCode } })}
						</Text>
					</>
				}
				attributes={{ "data-testid": "payment-currency-alert" }}
				dismissible={false}
				variant="hint"
				bleed
			/>
			<Text tagName="h2" variant="strong_2">
				{t("gt_mig_tr_currency_topcurrencies")}
			</Text>
			<Grid gap={2}>{filteredCurrencies.filter((currency) => currency.topCurrency === true).map(currencyToPill)}</Grid>
			<Text tagName="h2" variant="strong_2">
				{t("gt_mig_tr_currency_allcurrencies")}
			</Text>
			<Grid gap={2}>{filteredCurrencies.map(currencyToPill)}</Grid>
		</Stack>
	);
};
