import { LanguageData } from "../type";

export const defaultLanguageData: LanguageData = {
	id: 361,
	label: "English",
	code: "EN",
};

export const usLanguageData: LanguageData = {
	id: 521,
	label: "English (US)",
	code: "US",
};

export const malaysianLanguageData: LanguageData = {
	id: 551,
	label: "Bahasa Malaysia",
	code: "MY",
};

export const auLanguageData: LanguageData = {
	id: 7841,
	label: "English (AU)",
	code: "AU",
};

export const phLanguageData: LanguageData = {
	id: 2046,
	label: "Filipino",
	code: "PH",
};

export const brLanguageData: LanguageData = {
	id: 536,
	label: "Português (BR)",
	code: "BR",
};

export const rsLanguageData: LanguageData = {
	id: 1276,
	label: "Srpski",
	code: "RS",
};

export const grLanguageData: LanguageData = {
	id: 491,
	label: "ελληνικά",
	code: "GR",
};

export const jaLanguageData: LanguageData = {
	id: 451,
	label: "日本語",
	code: "JA",
};

export const eeLanguageData: LanguageData = {
	id: 1261,
	label: "Eesti",
	code: "EE",
};

export const isoLocaleOverwrites = {
	"en-au": auLanguageData,
	"en-us": usLanguageData,
	"ms-my": malaysianLanguageData,
	"en-ph": phLanguageData,
	"pt-br": brLanguageData,
	"sr-rs": rsLanguageData,
	"el-gr": grLanguageData,
	"ja-jp": jaLanguageData,
	"et-ee": eeLanguageData,
	"tl-ph": phLanguageData,
};

export const languages: readonly LanguageData[] = [
	defaultLanguageData,
	{
		id: 366,
		label: "Deutsch",
		code: "DE",
	},
	{
		id: 371,
		label: "Français",
		code: "FR",
	},
	{
		id: 391,
		label: "Español",
		code: "ES",
	},
	{
		id: 431,
		label: "Italiano",
		code: "IT",
	},
	usLanguageData,
	{
		id: 446,
		label: "עברית",
		code: "HE",
	},
	{
		id: 2506,
		label: "Bahasa Indonesia",
		code: "ID",
	},
	malaysianLanguageData,
	{
		id: 516,
		label: "Català",
		code: "CA",
	},
	{
		id: 421,
		label: "Dansk",
		code: "DA",
	},
	eeLanguageData,
	auLanguageData,
	phLanguageData,
	{
		id: 1266,
		label: "Hrvatski",
		code: "HR",
	},
	{
		id: 386,
		label: "Latvija",
		code: "LV",
	},
	{
		id: 406,
		label: "Lietuvių kalba",
		code: "LT",
	},
	{
		id: 496,
		label: "Magyar",
		code: "HU",
	},
	{
		id: 401,
		label: "Nederlands",
		code: "NL",
	},
	{
		id: 426,
		label: "Norsk",
		code: "NO",
	},
	{
		id: 501,
		label: "Polski",
		code: "PL",
	},
	brLanguageData,
	{
		id: 396,
		label: "Português (PT)",
		code: "PT",
	},
	{
		id: 511,
		label: "Pусский",
		code: "RU",
	},
	{
		id: 506,
		label: "Română",
		code: "RO",
	},
	{
		id: 436,
		label: "Slovenčina",
		code: "SK",
	},
	{
		id: 708,
		label: "Slovenščina",
		code: "SL",
	},
	rsLanguageData,
	{
		id: 411,
		label: "Suomi",
		code: "FI",
	},
	{
		id: 416,
		label: "Svenska",
		code: "SV",
	},
	{
		id: 28781,
		label: "Swahili",
		code: "SW",
	},
	{
		id: 476,
		label: "Türkçe",
		code: "TR",
	},
	{
		id: 1336,
		label: "Việt",
		code: "VI",
	},
	{
		id: 381,
		label: "Íslenska",
		code: "IS",
	},
	{
		id: 486,
		label: "Čeština",
		code: "CS",
	},
	grLanguageData,
	{
		id: 6136,
		label: "Български",
		code: "BG",
	},
	{
		id: 796,
		label: "Українська",
		code: "UK",
	},
	{
		id: 441,
		label: "العربية",
		code: "AR",
	},
	{
		id: 376,
		label: "ไทย",
		code: "TH",
	},
	jaLanguageData,
	{
		id: 526,
		label: "简体中文",
		code: "ZS",
	},
	{
		id: 466,
		label: "繁體中文",
		code: "ZH",
	},
	{
		id: 456,
		label: "한국어",
		code: "KO",
	},
	{
		id: 999,
		label: "हिंदी",
		code: "hi",
	},
	{
		id: 4623,
		label: "Español (MX)",
		code: "MX",
	},
];
