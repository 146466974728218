const removeCurrentlySelectedLanguageFromUrl = (path: string): string => {
	// if it has a locale like '/au/'
	if (path.startsWith("/") && path.charAt(3) === "/") {
		return path.slice(3, path.length);
	} // if it has a locale like '/es-ct/'
	if (path.startsWith("/") && path.charAt(6) === "/") {
		return path.slice(6, path.length);
	} // if url is something like www.abc.com/au
	if (path.startsWith("/") && path.length === 3) {
		return "";
	}
	return path;
};

const updateUrlWithLanguageCode = (language): void => {
	const updatedUrl = removeCurrentlySelectedLanguageFromUrl(window.location.pathname);
	window.location.pathname = `/${language.toLowerCase()}${updatedUrl}`;
};

const changeLanguage = (language: string, shouldModifyUrl = false): Promise<void> => {
	return fetch(`/api/header-footer/change-preference?preflang=${language}`).then(() => {
		if (shouldModifyUrl) {
			return updateUrlWithLanguageCode(language);
		}
		return window.location.reload();
	});
};

const changeCurrency = (currency: string): Promise<void> => {
	return fetch(`/api/header-footer/change-preference?prefcurrency=${currency}`).then(() => window.location.reload());
};

export { changeLanguage, changeCurrency };
