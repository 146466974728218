import { renderComponents } from "@btu-tools/master-builder-client";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import "../../components/Header/Header.css";
import "../../components/Footer/Footer.css";
import "core-js/stable/promise";
import "core-js/stable/object";
import "core-js/stable/array";
import "core-js/stable/string";

document.addEventListener("DOMContentLoaded", () => renderComponents({ Header, Footer }));

/* istanbul ignore next */
if (module.hot) {
	module.hot.accept("../../components/Header", () => {
		renderComponents({
			Header: require("../../components/Header").Header,
			Footer: require("../../components/Footer").Footer,
		});
	});
}
