import React, { ReactElement } from "react";
import { Icon } from "@bookingcom/bui-react";
import {
	BedIcon,
	TransportCarIcon,
	AttractionsIcon,
	TaxiSignIcon,
	TravelLuggageAltIcon,
	TransportAirplaneIcon,
} from "@bookingcom/bui-assets-react/streamline";

const ICON_MAPPING = {
	flights: TransportAirplaneIcon,
	cars: TransportCarIcon,
	attractions: AttractionsIcon,
	accommodation: BedIcon,
	airport_taxis: TaxiSignIcon,
	packages: TravelLuggageAltIcon,
};

export const getIcon = (name: string): ReactElement => {
	const MappedIcon = ICON_MAPPING[name];
	return <Icon svg={<MappedIcon />} />;
};
