import { registerDynamicTags, t } from "@bookingcom/lingojs-core";

export const DEFAULT_CURRENCY_CODE = "GBP";

export type Currency = {
	id: number;
	code: string;
	code_label?: string;
	label: string;
	topCurrency: boolean;
};

// currencies
registerDynamicTags(t("gt_mig_tr_currency_aud"));
registerDynamicTags(t("gt_mig_tr_currency_aed"));
registerDynamicTags(t("gt_mig_tr_currency_amd"));
registerDynamicTags(t("gt_mig_tr_currency_ang"));
registerDynamicTags(t("gt_mig_tr_currency_bgn"));
registerDynamicTags(t("gt_mig_tr_currency_bhd"));
registerDynamicTags(t("gt_mig_tr_currency_bnd"));
registerDynamicTags(t("gt_mig_tr_currency_bob"));
registerDynamicTags(t("gt_mig_tr_currency_brl"));
registerDynamicTags(t("gt_mig_tr_currency_bsd"));
registerDynamicTags(t("gt_mig_tr_currency_bwp"));
registerDynamicTags(t("gt_mig_tr_currency_bzd"));
registerDynamicTags(t("gt_mig_tr_currency_cad"));
registerDynamicTags(t("gt_mig_tr_currency_chf"));
registerDynamicTags(t("gt_mig_tr_currency_clp"));
registerDynamicTags(t("gt_mig_tr_currency_cny"));
registerDynamicTags(t("gt_mig_tr_currency_cop"));
registerDynamicTags(t("gt_mig_tr_currency_crc"));
registerDynamicTags(t("gt_mig_tr_currency_cve"));
registerDynamicTags(t("gt_mig_tr_currency_cyp"));
registerDynamicTags(t("gt_mig_tr_currency_czk"));
registerDynamicTags(t("gt_mig_tr_currency_dkk"));
registerDynamicTags(t("gt_mig_tr_currency_egp"));
registerDynamicTags(t("gt_mig_tr_currency_eur"));
registerDynamicTags(t("gt_mig_tr_currency_fjd"));
registerDynamicTags(t("gt_mig_tr_currency_gbp"));
registerDynamicTags(t("gt_mig_tr_currency_ghc"));
registerDynamicTags(t("gt_mig_tr_currency_gtq"));
registerDynamicTags(t("gt_mig_tr_currency_hkd"));
registerDynamicTags(t("gt_mig_tr_currency_hnl"));
registerDynamicTags(t("gt_mig_tr_currency_hrk"));
registerDynamicTags(t("gt_mig_tr_currency_huf"));
registerDynamicTags(t("gt_mig_tr_currency_idr"));
registerDynamicTags(t("gt_mig_tr_currency_ils"));
registerDynamicTags(t("gt_mig_tr_currency_inr"));
registerDynamicTags(t("gt_mig_tr_currency_isk"));
registerDynamicTags(t("gt_mig_tr_currency_jod"));
registerDynamicTags(t("gt_mig_tr_currency_jpy"));
registerDynamicTags(t("gt_mig_tr_currency_krw"));
registerDynamicTags(t("gt_mig_tr_currency_kwd"));
registerDynamicTags(t("gt_mig_tr_currency_kzt"));
registerDynamicTags(t("gt_mig_tr_currency_lek"));
registerDynamicTags(t("gt_mig_tr_currency_lkr"));
registerDynamicTags(t("gt_mig_tr_currency_mad"));
registerDynamicTags(t("gt_mig_tr_currency_mkd"));
registerDynamicTags(t("gt_mig_tr_currency_mnt"));
registerDynamicTags(t("gt_mig_tr_currency_mur"));
registerDynamicTags(t("gt_mig_tr_currency_mxn"));
registerDynamicTags(t("gt_mig_tr_currency_myr"));
registerDynamicTags(t("gt_mig_tr_currency_nad"));
registerDynamicTags(t("gt_mig_tr_currency_nok"));
registerDynamicTags(t("gt_mig_tr_currency_nzd"));
registerDynamicTags(t("gt_mig_tr_currency_omr"));
registerDynamicTags(t("gt_mig_tr_currency_pab"));
registerDynamicTags(t("gt_mig_tr_currency_pen"));
registerDynamicTags(t("gt_mig_tr_currency_php"));
registerDynamicTags(t("gt_mig_tr_currency_pkr"));
registerDynamicTags(t("gt_mig_tr_currency_pln"));
registerDynamicTags(t("gt_mig_tr_currency_qar"));
registerDynamicTags(t("gt_mig_tr_currency_ron"));
registerDynamicTags(t("gt_mig_tr_currency_rsd"));
registerDynamicTags(t("gt_mig_tr_currency_rub"));
registerDynamicTags(t("gt_mig_tr_currency_sar"));
registerDynamicTags(t("gt_mig_tr_currency_sek"));
registerDynamicTags(t("gt_mig_tr_currency_sgd"));
registerDynamicTags(t("gt_mig_tr_currency_skk"));
registerDynamicTags(t("gt_mig_tr_currency_thb"));
registerDynamicTags(t("gt_mig_tr_currency_tnd"));
registerDynamicTags(t("gt_mig_tr_currency_try"));
registerDynamicTags(t("gt_mig_tr_currency_ttd"));
registerDynamicTags(t("gt_mig_tr_currency_twd"));
registerDynamicTags(t("gt_mig_tr_currency_tzs"));
registerDynamicTags(t("gt_mig_tr_currency_uah"));
registerDynamicTags(t("gt_mig_tr_currency_usd"));
registerDynamicTags(t("gt_mig_tr_currency_uyu"));
registerDynamicTags(t("gt_mig_tr_currency_veb"));
registerDynamicTags(t("gt_mig_tr_currency_vef"));
registerDynamicTags(t("gt_mig_tr_currency_vnd"));
registerDynamicTags(t("gt_mig_tr_currency_vuv"));
registerDynamicTags(t("gt_mig_tr_currency_wst"));
registerDynamicTags(t("gt_mig_tr_currency_xaf"));
registerDynamicTags(t("gt_mig_tr_currency_xof"));
registerDynamicTags(t("gt_mig_tr_currency_xpf"));
registerDynamicTags(t("gt_mig_tr_currency_zar"));

export const currencies: Record<string, Currency> = {
	AUD: {
		id: 1,
		code: "AUD",
		label: "gt_mig_tr_currency_aud",
		topCurrency: true,
	},
	AED: {
		id: 2,
		code: "AED",
		label: "gt_mig_tr_currency_aed",
		topCurrency: false,
	},
	AMD: {
		id: 3,
		code: "AMD",
		label: "gt_mig_tr_currency_amd",
		topCurrency: false,
	},
	ANG: {
		id: 4,
		code: "ANG",
		label: "gt_mig_tr_currency_ang",
		topCurrency: false,
	},
	BGN: {
		id: 6,
		code: "BGN",
		label: "gt_mig_tr_currency_bgn",
		topCurrency: false,
	},
	BHD: {
		id: 7,
		code: "BHD",
		label: "gt_mig_tr_currency_bhd",
		topCurrency: false,
	},
	BND: {
		id: 8,
		code: "BND",
		label: "gt_mig_tr_currency_bnd",
		topCurrency: false,
	},
	BOB: {
		id: 9,
		code: "BOB",
		label: "gt_mig_tr_currency_bob",
		topCurrency: false,
	},
	BRL: {
		id: 10,
		code: "BRL",
		label: "gt_mig_tr_currency_brl",
		topCurrency: false,
	},
	BSD: {
		id: 11,
		code: "BSD",
		label: "gt_mig_tr_currency_bsd",
		topCurrency: false,
	},
	BWP: {
		id: 12,
		code: "BWP",
		label: "gt_mig_tr_currency_bwp",
		topCurrency: false,
	},
	BZD: {
		id: 13,
		code: "BZD",
		label: "gt_mig_tr_currency_bzd",
		topCurrency: false,
	},
	CAD: {
		id: 14,
		code: "CAD",
		label: "gt_mig_tr_currency_cad",
		topCurrency: true,
	},
	CHF: {
		id: 15,
		code: "CHF",
		label: "gt_mig_tr_currency_chf",
		topCurrency: false,
	},
	CLP: {
		id: 16,
		code: "CLP",
		label: "gt_mig_tr_currency_clp",
		topCurrency: false,
	},
	CNY: {
		id: 17,
		code: "CNY",
		label: "gt_mig_tr_currency_cny",
		topCurrency: false,
	},
	COP: {
		id: 18,
		code: "COP",
		label: "gt_mig_tr_currency_cop",
		topCurrency: false,
	},
	CRC: {
		id: 19,
		code: "CRC",
		label: "gt_mig_tr_currency_crc",
		topCurrency: false,
	},
	CVE: {
		id: 20,
		code: "CVE",
		label: "gt_mig_tr_currency_cve",
		topCurrency: false,
	},
	CYP: {
		id: 21,
		code: "CYP",
		label: "gt_mig_tr_currency_cyp",
		topCurrency: false,
	},
	CZK: {
		id: 22,
		code: "CZK",
		label: "gt_mig_tr_currency_czk",
		topCurrency: false,
	},
	DKK: {
		id: 23,
		code: "DKK",
		label: "gt_mig_tr_currency_dkk",
		topCurrency: false,
	},
	EGP: {
		id: 24,
		code: "EGP",
		label: "gt_mig_tr_currency_egp",
		topCurrency: false,
	},
	EUR: {
		id: 25,
		code: "EUR",
		code_label: "€",
		label: "gt_mig_tr_currency_eur",
		topCurrency: true,
	},
	FJD: {
		id: 26,
		code: "FJD",
		label: "gt_mig_tr_currency_fjd",
		topCurrency: false,
	},
	GBP: {
		id: 27,
		code: "GBP",
		code_label: "£",
		label: "gt_mig_tr_currency_gbp",
		topCurrency: true,
	},
	GHC: {
		id: 28,
		code: "GHC",
		label: "gt_mig_tr_currency_ghc",
		topCurrency: false,
	},
	GTQ: {
		id: 29,
		code: "GTQ",
		label: "gt_mig_tr_currency_gtq",
		topCurrency: false,
	},
	HKD: {
		id: 30,
		code: "HKD",
		label: "gt_mig_tr_currency_hkd",
		topCurrency: false,
	},
	HNL: {
		id: 31,
		code: "HNL",
		label: "gt_mig_tr_currency_hnl",
		topCurrency: false,
	},
	HRK: {
		id: 32,
		code: "HRK",
		label: "gt_mig_tr_currency_hrk",
		topCurrency: false,
	},
	HUF: {
		id: 33,
		code: "HUF",
		label: "gt_mig_tr_currency_huf",
		topCurrency: false,
	},
	IDR: {
		id: 34,
		code: "IDR",
		code_label: "Rp",
		label: "gt_mig_tr_currency_idr",
		topCurrency: false,
	},
	ILS: {
		id: 35,
		code: "ILS",
		code_label: "₪",
		label: "gt_mig_tr_currency_ils",
		topCurrency: false,
	},
	INR: {
		id: 36,
		code: "INR",
		label: "gt_mig_tr_currency_inr",
		topCurrency: false,
	},
	ISK: {
		id: 37,
		code: "ISK",
		label: "gt_mig_tr_currency_isk",
		topCurrency: false,
	},
	JOD: {
		id: 38,
		code: "JOD",
		label: "gt_mig_tr_currency_jod",
		topCurrency: false,
	},
	JPY: {
		id: 39,
		code: "JPY",
		code_label: "￥",
		label: "gt_mig_tr_currency_jpy",
		topCurrency: false,
	},
	KRW: {
		id: 40,
		code: "KRW",
		code_label: "₩",
		label: "gt_mig_tr_currency_krw",
		topCurrency: false,
	},
	KWD: {
		id: 41,
		code: "KWD",
		label: "gt_mig_tr_currency_kwd",
		topCurrency: false,
	},
	KZT: {
		id: 42,
		code: "KZT",
		label: "gt_mig_tr_currency_kzt",
		topCurrency: false,
	},
	LEK: {
		id: 43,
		code: "LEK",
		label: "gt_mig_tr_currency_lek",
		topCurrency: false,
	},
	LKR: {
		id: 44,
		code: "LKR",
		label: "gt_mig_tr_currency_lkr",
		topCurrency: false,
	},
	MAD: {
		id: 45,
		code: "MAD",
		label: "gt_mig_tr_currency_mad",
		topCurrency: false,
	},
	MKD: {
		id: 46,
		code: "MKD",
		label: "gt_mig_tr_currency_mkd",
		topCurrency: false,
	},
	MNT: {
		id: 47,
		code: "MNT",
		label: "gt_mig_tr_currency_mnt",
		topCurrency: false,
	},
	MUR: {
		id: 49,
		code: "MUR",
		label: "gt_mig_tr_currency_mur",
		topCurrency: false,
	},
	MXN: {
		id: 50,
		code: "MXN",
		label: "gt_mig_tr_currency_mxn",
		topCurrency: false,
	},
	MYR: {
		id: 51,
		code: "MYR",
		label: "gt_mig_tr_currency_myr",
		topCurrency: false,
	},
	NAD: {
		id: 52,
		code: "NAD",
		label: "gt_mig_tr_currency_nad",
		topCurrency: false,
	},
	NOK: {
		id: 53,
		code: "NOK",
		label: "gt_mig_tr_currency_nok",
		topCurrency: false,
	},
	NZD: {
		id: 54,
		code: "NZD",
		label: "gt_mig_tr_currency_nzd",
		topCurrency: false,
	},
	OMR: {
		id: 55,
		code: "OMR",
		label: "gt_mig_tr_currency_omr",
		topCurrency: false,
	},
	PAB: {
		id: 56,
		code: "PAB",
		label: "gt_mig_tr_currency_pab",
		topCurrency: false,
	},
	PEN: {
		id: 57,
		code: "PEN",
		label: "gt_mig_tr_currency_pen",
		topCurrency: false,
	},
	PHP: {
		id: 58,
		code: "PHP",
		code_label: "₱",
		label: "gt_mig_tr_currency_php",
		topCurrency: false,
	},
	PKR: {
		id: 59,
		code: "PKR",
		label: "gt_mig_tr_currency_pkr",
		topCurrency: false,
	},
	PLN: {
		id: 60,
		code: "PLN",
		label: "gt_mig_tr_currency_pln",
		topCurrency: false,
	},
	QAR: {
		id: 61,
		code: "QAR",
		label: "gt_mig_tr_currency_qar",
		topCurrency: false,
	},
	RON: {
		id: 62,
		code: "RON",
		label: "gt_mig_tr_currency_ron",
		topCurrency: false,
	},
	RSD: {
		id: 63,
		code: "RSD",
		label: "gt_mig_tr_currency_rsd",
		topCurrency: false,
	},
	RUB: {
		id: 64,
		code: "RUB",
		label: "gt_mig_tr_currency_rub",
		topCurrency: false,
	},
	SAR: {
		id: 65,
		code: "SAR",
		label: "gt_mig_tr_currency_sar",
		topCurrency: false,
	},
	SEK: {
		id: 66,
		code: "SEK",
		label: "gt_mig_tr_currency_sek",
		topCurrency: false,
	},
	SGD: {
		id: 67,
		code: "SGD",
		label: "gt_mig_tr_currency_sgd",
		topCurrency: false,
	},
	SKK: {
		id: 68,
		code: "SKK",
		label: "gt_mig_tr_currency_skk",
		topCurrency: false,
	},
	THB: {
		id: 69,
		code: "THB",
		label: "gt_mig_tr_currency_thb",
		topCurrency: false,
	},
	TND: {
		id: 70,
		code: "TND",
		label: "gt_mig_tr_currency_tnd",
		topCurrency: false,
	},
	TRY: {
		id: 71,
		code: "TRY",
		label: "gt_mig_tr_currency_try",
		topCurrency: false,
	},
	TTD: {
		id: 72,
		code: "TTD",
		label: "gt_mig_tr_currency_ttd",
		topCurrency: false,
	},
	TWD: {
		id: 73,
		code: "TWD",
		label: "gt_mig_tr_currency_twd",
		topCurrency: false,
	},
	TZS: {
		id: 74,
		code: "TZS",
		label: "gt_mig_tr_currency_tzs",
		topCurrency: false,
	},
	UAH: {
		id: 75,
		code: "UAH",
		label: "gt_mig_tr_currency_uah",
		topCurrency: false,
	},
	USD: {
		id: 76,
		code: "USD",
		code_label: "US$",
		label: "gt_mig_tr_currency_usd",
		topCurrency: true,
	},
	UYU: {
		id: 77,
		code: "UYU",
		label: "gt_mig_tr_currency_uyu",
		topCurrency: false,
	},
	VEB: {
		id: 78,
		code: "VEB",
		label: "gt_mig_tr_currency_veb",
		topCurrency: false,
	},
	VEF: {
		id: 79,
		code: "VEF",
		label: "gt_mig_tr_currency_vef",
		topCurrency: false,
	},
	VND: {
		id: 80,
		code: "VND",
		code_label: "₫",
		label: "gt_mig_tr_currency_vnd",
		topCurrency: false,
	},
	VUV: {
		id: 81,
		code: "VUV",
		label: "gt_mig_tr_currency_vuv",
		topCurrency: false,
	},
	WST: {
		id: 82,
		code: "WST",
		label: "gt_mig_tr_currency_wst",
		topCurrency: false,
	},
	XAF: {
		id: 83,
		code: "XAF",
		label: "gt_mig_tr_currency_xaf",
		topCurrency: false,
	},
	XOF: {
		id: 84,
		code: "XOF",
		label: "gt_mig_tr_currency_xof",
		topCurrency: false,
	},
	XPF: {
		id: 85,
		code: "XPF",
		label: "gt_mig_tr_currency_xpf",
		topCurrency: false,
	},
	ZAR: {
		id: 86,
		code: "ZAR",
		label: "gt_mig_tr_currency_zar",
		topCurrency: false,
	},
};
