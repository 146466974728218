import React, { FC } from "react";
import { Box, Tab } from "@bookingcom/bui-react";
import { HeaderTab } from "../../util/header/product-tabs";

export interface NavBarProps {
	tabs: Array<HeaderTab>;
	pageName?: string;
	fireInteractionEvent?: (action: string, label: string, info?: string) => void;
}

export const NavBar: FC<NavBarProps> = ({ tabs, pageName, fireInteractionEvent }) => {
	const category = pageName ? `${pageName} rentalcars_by_booking` : "rentalcars_by_booking";

	return (
		<Box padding={2} orientation="vertical">
			<Tab
				variant="rounded"
				color="inherit"
				defaultActiveTabId="cars"
				className="navbar-tabs"
				onTabChange={(tabId) => fireInteractionEvent?.(category, "click", tabId)}
			>
				<Tab.TriggerList>
					{tabs?.map((trigger) => (
						<Tab.Trigger
							key={trigger.id}
							linkAttributes={{
								target: trigger.id === "cars" ? "_self" : "_blank",
								rel: "noopener noreferrer",
								"data-testid": trigger.id,
							}}
							{...trigger}
						/>
					))}
				</Tab.TriggerList>
			</Tab>
		</Box>
	);
};
